@import '../../../common/variables';
@import '../../../common/mixins';

.types {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	> div {
		width: 100%;
	}

	.isDonationActive {
		[class*='ms-ChoiceFieldGroup-flexContainer'] {
			> div:last-of-type {
				background: $buttonBgBlue;
				label {
					&::before {
						background-color: $buttonBgBlue;
					}
				}
			}
		}
	}

	.isSponsoringActive {
		[class*='ms-ChoiceFieldGroup-flexContainer'] {
			> div:first-of-type {
				background: $buttonBgBlue;
				label {
					&::before {
						background-color: $buttonBgBlue;
					}
				}
			}
		}
	}
	[class*='ms-ChoiceFieldGroup-flexContainer'] {
		display: flex;
		gap: 20px;

		> div {
			background: $headerGray;
			margin-top: 0;
			padding: 1rem;
			width: 50%;
		}

		label {
			color: $white;

			&:hover {
				span {
					color: $white;
				}
			}

			&::before {
				border-color: $white;
				background-color: $headerGray;
			}

			&::after {
				background: $white;
				border-color: $white;
			}
		}
	}

	.typesBox {
		width: 100%;
		display: flex;
		align-items: stretch;
		gap: 20px;

		div {
			width: 50%;
			border: 1px solid $headerGray;
			padding: 1rem;
			flex: 1;

			h4 {
				@include header4;

				text-align: left;
			}
		}
	}
}
